footer{
    position: fixed;
    z-index: 3;
    bottom: 10px;
    text-align: center;
    color: whitesmoke;
    display: inline-block;
    width: 100vw;
    font-size: 20px;
    transition-duration: 400ms;
}

.footer-hidden{
    bottom: -20px;
}

@media screen and (max-width: 768px){
    footer{
        position: static;
        background-color: var(--background-main);
        padding-bottom: 20px;
    }

    .footer-hidden{
        transition-duration: 200ms;
        bottom: -100vh;
        position: relative;

    }
    
}

