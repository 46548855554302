.menu-list {
    position: fixed;
    transition-duration: 0.2s;
    height: 100vh;
    z-index: 50;
    top : -100vh;
    background-color: var(--menu-backgroud-color);
    width: 100vw;
    padding-top: 20vh;
    padding-bottom: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.menu-list-visible {
    top: 0;
}
  
  
.menu-item{
    position: relative;
    transition-duration: 1s;
    top: -100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    width: 100%;
}
  
  
.menu-item-portfolio{
    transition-delay: 0.2s;
}

.menu-item-about{
    transition-delay: 0.4s;
}

.menu-item-visible{
    top: 0;
}
  
.menu-item span{
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 600px;
    width: 90%;
    font-size: max(3.5vw, 35px);
    color: var(--menu-item-color);
    height: 20vh;
    overflow: hidden;
    position: relative;
}
  
.menu-item:hover span{
    color: var(--menu-item-hover-color);
    text-shadow: 0 0 10px var(--menu-item-hover-shadow-color);
}
  
.menu-list .active span {
    color: var(--menu-item-active-color);
    text-shadow: 0 0 10px var(--menu-item-active-shadow-color);
}
  
.menu-item span::after{
    content: "";
    position: absolute;
    display: block;
    background-color: var(--menu-item-after-before-color);
    height: 15vh;
    align-self: flex-end;
    max-width: 600px;
    width: 100%;
    transition-duration: 0.4s;
    z-index: -2;
    transform: translate3d(100%,0,0);
}
  
.menu-item span::before{
    content: "";
    position: absolute;
    display: block;
    background-color: var(--menu-item-after-before-color);
    height: 15vh;
    align-self: flex-start;
    max-width: 600px;
    width: 100%;
    transition-duration: 0.4s;
    z-index: -1;
    transform: translate3d(-100%,0,0);
}
  
.menu-item:hover span::after{
    transform: translate3d(0,0,0);
    transition-duration: 0.8s;
}
  
.menu-item:hover span::before{
    transform: translate3d(0,0,0);
    transition-duration: 0.8s;
}

