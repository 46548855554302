:root {
  --background: rgb(60, 60, 60);

  --text-color: whitesmoke;
  --background-main: rgb(40,40,40);
  --arrow-color: rgb(150,150,150);
  --arrow-after-text-color: rgb(170,170,170);
  --github: gray;
  --linkedin: #2867b2;
  --submit-button: rgb(150,150,150);
  --submit-button-hover: rgb(170,170,170);
  --cv-hover: rgb(170,170,170);
  --github-button-hover: rgba(100, 100, 100, 0.5);
  --linkdedin-button-hover: rgba(7, 22, 109, 0.5);
  --name-color: rgb(180,180,180);
  --project-color: rgba(83, 83, 83, 0.842);
  --project-item-color: rgb(49, 49, 49);
  --project-text-color: rgb(141, 141, 141);
  --menu-backgroud-color: rgb(55, 55, 55);
  --hamburger-menu-color: rgb(143, 143, 143);
  --menu-item-color: rgb(150, 150, 150);
  --menu-item-hover-color: rgb(210, 210, 210);
  --menu-item-hover-shadow-color: black;
  --menu-item-active-color: rgb(230, 230, 230);
  --menu-item-active-shadow-color: rgb(37,37,37);
  --menu-item-after-before-color: rgb(46, 46, 46);
}

.App{
    background-color: var(--background);
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}


::-webkit-scrollbar{
  width: 10px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--background-main); 
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--hamburger-menu-color); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black; 
}



@media screen and (max-width: 992px){
  ::-webkit-scrollbar{
    width: 0;
  }

  .App{
    overflow-x: hidden;
    overflow-y: auto;
  }
  
}

