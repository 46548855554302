  
.language{
  position: fixed;
  top: 30px;
  left: 10px;
  z-index: 25;
}

.language-show{
  animation-name: languageShow;
  animation-duration: 0.3s;
}

@keyframes languageShow {
  0%{
    left: -50px;
  }
  100%{
    left: 10px;
  }
}

.language button{
  background-color: var(--background-main);
  color: var(--text-color);
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 20px;
}

.language button:focus{
  animation-name: language;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes language{
  from{
      border-bottom: none;
  }
  to{
      border-bottom: 1px solid var(--text-color);
  }
}

.language button:hover{
  font-size: 30px;
}

.language-hide{
  left: -50px;
  transition-duration: 0.1s;
}


/*************************************************************************************************
hamburger-menu
*************************************************************************************************/
.hamburger-menu-label{
  display: block;
  width: 54px;
  height: 66px;
  position: fixed;
  left: calc(95% - 40px);
  top: 50px;
  cursor: pointer;
  z-index: 100;
}



.hamburger-menu {
  top: 20px;
  width: 75%;
  left: 7px;
}

.hamburger-menu, .hamburger-menu::after, .hamburger-menu::before{
  display: block;
  height: 4px;
  background-color: var(--hamburger-menu-color);
  position: relative;
  transition-duration: 0.1s;
}

.hamburger-menu::before{
  content: "";
  top: 10px;
}

.hamburger-menu::after{
  content: "";
  top: 15px;
}

.hamburger-menu-label:hover .hamburger-menu-hov{
  top: 15px;
}

.hamburger-menu-label:hover .hamburger-menu-hov::before{
  top: 15px;
}

.hamburger-menu-label:hover .hamburger-menu-hov::after{
  top: 25px;
}

.hamburger-menu-label:focus .hamburger-menu-hov{
  top: 15px;
}

.hamburger-menu-label:focus .hamburger-menu-hov::before{
  top: 15px;
}

.hamburger-menu-label:focus .hamburger-menu-hov::after{
  top: 25px;
}


.hamburger-menu-appearance {
  animation-name: hamburger-menu-appearance;
  animation-duration: 0.4s;
}

.hamburger-menu-appearance::after {
  animation-name: hamburger-menu-appearance;
  animation-duration: 0.4s;
  animation-delay: 0.1s;
}

.hamburger-menu-appearance::before {
  animation-name: hamburger-menu-appearance;
  animation-duration: 0.4s;
  animation-delay: 0.05s;
}


@keyframes hamburger-menu-appearance {
  from{
    left: 20vw;
  }
  to{
    left: 0;
  }
}


.hamburger-menu-disappearance {
  animation-name: hamburger-menu-disappearance;
  animation-fill-mode:forwards;
  animation-duration: 0.4s;
  animation-delay: 0.1s;
  
}

.hamburger-menu-disappearance::after {
  animation-name: hamburger-menu-disappearance;
  animation-duration: 0.2s;
}

.hamburger-menu-disappearance::before {
  animation-name: hamburger-menu-disappearance;
  animation-duration: 0.4s;
  animation-delay: 0.05s;
}


@keyframes hamburger-menu-disappearance {
  from{
    left: 0;
  }
  to{
    left: 20vw;
  }
}


.hamburger-menu-rot{
  rotate: 180deg;
}

.hamburger-menu-rot::before{
  top: 0;
}

.hamburger-menu-rot::after{
  top: -5px;
}

.hamburger-menu-rot-two{
  width: 60px;
  top: 30px;
  left: -2px;
  rotate: 60deg;
  animation-name: hamburger-label-rotate;
  animation-duration: 600ms;
}



.hamburger-menu-rot-two::after{
  rotate: 60deg;
}

.hamburger-menu-label:hover .hamburger-menu-rot-two{
  rotate: 70deg;
}
.hamburger-menu-label:hover .hamburger-menu-rot-two::after{
  rotate: 40deg;
}

.hamburger-menu-label:focus .hamburger-menu-rot-two{
  rotate: 70deg;
}
.hamburger-menu-label:focus .hamburger-menu-rot-two::after{
  rotate: 40deg;
}

@keyframes hamburger-label-rotate{
  0%{
    rotate: 180deg;
  }
  50%{
    rotate: 45deg;
  }
  100%{
    rotate: 60deg;
  }
}

@media screen and (max-width: 768px){
  .hamburger-menu-label{
    top: 10px;
  }
}
  