/*************************************************************************************************************
home page
**************************************************************************************************************/
*{
    font-weight: bold;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 99vh;
    transition-duration: 0.1s;
    opacity: 0;
}

.about h1{
    color: var(--text-color);
    font-size: 3.5vw;
    margin-bottom: 20px;
}

.about h1 .name {
    color: var(--name-color);
}

.about p {
    color: var(--text-color);
    font-size: 2vw;
    text-align: center;
}

.cv-container{
    margin-top: 30px;
    font-size: 2vw;
    text-align: center;
}
.cv-container a:hover{
    color: var(--cv-hover);
}

.cv-container a{
    color: var(--text-color);
    font-size: max(2vw, 30px);
    text-align: center;
    cursor: pointer;
}

.cv-container a:focus{
    animation-name: cv-button;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

}

@keyframes cv-button{
    from{
        border-bottom: none;
    }
    to{
        border-bottom: 1px solid var(--text-color);
    }
}


.portrait{
    width: 20vw;
    min-width: 250px;
    margin: 10px;
    clip-path: circle(40%);
}

.about-visible{
    animation-name: about-visible;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

}

@keyframes about-visible{
    from{
        opacity: 0;
        width: 90vw;
    }
    to{
        opacity: 1;
        width: 100vw;
    }
}

/*.about-hidden{
    animation-name: about-hidden;
    animation-duration: 0.05s;
    animation-fill-mode: forwards;
}

@keyframes about-hidden{
    from{
        opacity: 1;
        width: 100vw;
    }
    to{
        opacity: 0;
        width: 90vw;
    }
}*/


.about-cursor{
    animation: cursor-show 0.75s infinite;
}

@keyframes cursor-show{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


/****************************************************************************************************************
contact page
*****************************************************************************************************************/


.contact-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 99vh;
}

.contact {
    width: 50vw;
    text-align: start;
}

.contact h2, .contact h1 {
    color: var(--text-color);
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: max(3vw, 40px);
}

.contact p{
    margin-left: 20px;
    margin-bottom: 20px;
    color: var(--arrow-after-text-color);
    width: 40vw;
}

.contact .github-linkedin-button-heading{
    margin-top: 60px;
}

.form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
    margin-bottom: 20px;
}

.form input, form textarea {
    width: 100%;
    background: none;
    margin: 20px 0 20px 20px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-width: 2px;
    border-color: var(--arrow-color);
    min-height: 40px;
    font-size: 15px;
    color: var(--text-color);
    box-sizing: border-box;
    font-family: Arial;
}


.form input:focus, form textarea:focus {
    border-color: var(--text-color);
}



@-moz-document url-prefix(){
    .form textarea{
        scrollbar-width: thin;
        scrollbar-color: var(--hamburger-menu-color) var(--background-main);
    }

}




.form textarea{
    resize: none;
    min-height: 100px;
}

.form .submit-button {
    border: none;
    background-color: var(--submit-button);
    width: 20vw;
    font-size: 15px;
    border-radius: 3px;
    min-width: 180px;
    max-width: 300px;
    min-width: 220px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.form .submit-button:focus {
    background-color: var(--submit-button-hover);
}

.form .submit-button:hover{
    background-color: var(--submit-button-hover);
}

.form .submit-button:active{
    position: relative;
    top: 2px;
}

.github-linkedin-button-container{
    display: flex;
}

.github-linkedin-button-container .github-button{
    margin-right: 10px;
    margin-left: 20px;
    
    background-color: var(--github);
    max-width: 200px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.github-linkedin-button-container .github-button::after{
    background-color: var(--github-button-hover);
    z-index: 10;
    position: absolute;
    border-radius: 3px;
    content: "";
    width: 120px;
    height: 40px;
    transition-duration: 0.8s;
    transform: translate3d(-100%,0,0);
}

.github-linkedin-button-container .github-button:hover::after{
    transform: translate3d(0,0,0);
}

.github-linkedin-button-container .github-button:focus::after{
    transform: translate3d(0,0,0);
}


.github-button-img{
    width: 20px;
    height: 20px;
    margin-left: 20px;
    margin-right: 5px;
}




.github-linkedin-button-container .github-button-text{
    padding-right: 20px;
    color: var(--text-color);
}


.github-linkedin-button-container .linkedin-button{
    
    background-color: var(--linkedin);
    border-radius: 3px;
    max-width: 300px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.github-linkedin-button-container .linkedin-button::after{
    background-color: var(--linkdedin-button-hover);
    z-index: 10;
    position: absolute;
    border-radius: 3px;
    content: "";
    width: 150px;
    height: 40px;
    transition-duration: 0.8s;
    transform: translate3d(-100%,0,0);
}

.github-linkedin-button-container .linkedin-button:hover::after{
    transform: translate3d(0,0,0);
}

.github-linkedin-button-container .linkedin-button:focus::after{
    transform: translate3d(0,0,0);
}

.linkedin-button-img{
    width: 20px;
    height: 20px;
    margin-left: 20px;
    margin-right: 5px;
    border-radius: 3px;
}

.github-linkedin-button-container .linkedin-button-text{
    padding-right: 20px;
    color: var(--text-color);
}




/**********************************************************************************************************************
portfolio page
**********************************************************************************************************************/

.portfolio {
    margin-top: 80px;
}

.portfolio h1{
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color);
}

.portfolio .skills{
    color: var(--text-color);
    text-align: center;
    margin: 20px;
}

.project-container{
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 50px;
    margin: 60px 290px 0;
}

.project{
    width: 100%;
    height: 260px;
    overflow: hidden;
    min-width: 281px;
    max-width: calc(50vw - 290px - 25px);
}

.project img{
    width: 100%;
    height: 260px;
    object-fit: cover;
}

.focus-link{
    cursor: auto;
}

.project-text-link{
    width: 100%;
    height: 101%;
    background-color: var(--project-color);
    position: relative;
    transition-duration: 0.2s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.project:hover .project-text-link{
    transition-duration: 0.5s;
    transform: translateY(-265px);
}

.project:has(.focus-link:focus) .project-text-link{
    transition-duration: 0.5s;
    transform: translateY(-265px);
}

.projetc-h2-conatiner{
    overflow: hidden;
    margin: 5px 5px;
}

.projetc-h2{
    display: inline-block;
    background-color: var(--project-item-color);
    border-radius: 1px;
    border-width: 1px 1px 2px 1px;
    border-style: solid;
    border-color: black;
    box-shadow:1px 1px 0 black;
    padding: 3px;
    transition: transform .285s cubic-bezier(.5,1.5,.5,1.5);
    color: var(--project-text-color);
    transform: translateY(-80px);
    opacity: 0;
    -webkit-transform: rotateX(90deg);
}

.project:hover .projetc-h2{
    transition-delay: 0.5s;
    transition-duration: 0.3s;
    transform: rotateX(0) translate3d(0,0,0);
    opacity: 1;
}

.project:has(.focus-link:focus) .projetc-h2{
    transition-delay: 0.5s;
    transition-duration: 0.3s;
    transform: rotateX(0) translate3d(0,0,0);
    opacity: 1;
}

.project-tech-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 0 10px;
}

.project-tech-item-container{
    overflow: hidden;
}

.project-tech-item{
    position: relative;
    display: inline-block;
    background-color: var(--project-item-color);
    border-radius: 1px;
    border-width: 1px 1px 2px 1px;
    border-style: solid;
    border-color: black;
    box-shadow:1px 1px 0 black;
    padding: 3px;
    margin: 5px;
    color: var(--project-text-color);
    transform: translateY(-80px);
    transition: transform .285s cubic-bezier(.5,1.5,.5,1.5);
    -webkit-transform: rotateX(90deg);
    opacity: 0;
}

.project:hover .project-tech-item{
    transition-duration: 0.3s;
    transform: rotateX(0) translate3d(0,0,0);
    opacity: 1;
}
.project:has(.focus-link:focus) .project-tech-item{
    transition-duration: 0.3s;
    transform: rotateX(0) translate3d(0,0,0);
    opacity: 1;
}

.project:hover .project-tech-item1{
    transition-delay: 0.5s;
}
.project:has(.focus-link:focus) .project-tech-item1{
    transition-delay: 0.5s;
}

.project:hover .project-tech-item2{
    transition-delay: 0.53s;
}

.project:has(.focus-link:focus) .project-tech-item2{
    transition-delay: 0.53s;
}

.project:has(.focus-link:focus) .project-tech-item3{
    transition-delay: 0.56s;
}

.project:hover .project-tech-item3{
    transition-delay: 0.56s;
}

.project:hover .project-tech-item4{
    transition-delay: 0.59s;
}

.project:has(.focus-link:focus) .project-tech-item4{
    transition-delay: 0.59s;
}

.project:hover .project-tech-item5{
    transition-delay: 0.62s;
}

.project:has(.focus-link:focus) .project-tech-item5{
    transition-delay: 0.62s;
}

.project:hover .project-tech-item6{
    transition-delay: 0.65s;
}

.project:has(.focus-link:focus) .project-tech-item6{
    transition-delay: 0.65s;
}

.github-pages-conatiner{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.github-pages-conatiner a{
    margin: 5px;
    transform: translateY(-80px);
    transition: transform .285s cubic-bezier(.5,1.5,.5,1.5);
    -webkit-transform: rotateX(90deg);
    opacity: 0;
    border-radius: 40%;
    color: var(--text-color);
    display: block;
    position: relative;
}

.github-pages-conatiner a img{
    width: 30px;
    height: 30px;
    background-color: var(--project-item-color);
    border-radius: 50%;
}

.project:hover .github-pages-conatiner a{
    transition-delay: 0.5s;
    transition-duration: 0.3s;
    transform: rotateX(0) translate3d(0,0,0);
    opacity: 1;
}

.project:has(.focus-link:focus) .github-pages-conatiner a{
    transition-delay: 0.5s;
    transition-duration: 0.3s;
    transform: rotateX(0) translate3d(0,0,0);
    opacity: 1;
}



/**********************************************************************************************************************
right arrow
***********************************************************************************************************************/





nav .directionRight{
    width: 200px;
    height: 200px;
    display: inline-block;
    position: fixed;
    top: calc(50vh - 100px);
    z-index: 20;
    right: 0;
}

nav .directionRight span{
    width: 40%;
    background-color: var(--arrow-color);
    height: 3px;
    display: block;
    position: relative;
    top: 75px;
    left: 60px;

}

nav .directionRight span::before{
    content: "";
    width: 100%;
    background-color: var(--arrow-color);
    height: 3px;
    display: block;
    position: relative;
}

nav .directionRight:hover span{
    rotate: 50deg;
}

nav .directionRight:focus span{
    rotate: 50deg;
}

nav .directionRight:hover span::before{
    rotate: 80deg;
    top: 38px;
    left: 45px;
}

nav .directionRight:focus span::before{
    rotate: 80deg;
    top: 38px;
    left: 45px;
}

nav .directionRight::after{
    content: "";
    position: relative;
    color: var(--arrow-after-text-color);
    top: 83px;
    right: 40px;
    font-size: max(1.5vw, 20px);
    text-shadow: 0 0 1vw whitesmoke;
    opacity: 0;
    transition-duration: 0.5s;
}

nav .directionRightHome::after{
    content: "Portfolio";
}

nav .directionRightPortfolio::after{
    content: "Contact";
}

nav .directionRight:hover::after {
    right:  80px;
    opacity: 1;
}

nav .directionRight:focus::after {
    right: 80px;
    opacity: 1;
}

nav .directionRightDisappearance span {
    rotate: 0deg;
    transition-duration: 0.2s;
}

nav .directionRightDisappearance:hover span {
    rotate: 0deg;
    transition-duration: 0.2s;
}

nav .directionRightDisappearance:focus span {
    rotate: 0deg;
    transition-duration: 0.2s;
}

nav .directionRightDisappearance span::before {
    rotate: 180deg;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    visibility: hidden;
}

nav .directionRightDisappearance:hover span::before {
    rotate: 180deg;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    visibility: hidden;
}

nav .directionRightDisappearance:focus span::before {
    rotate: 180deg;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    visibility: hidden;
}

nav .directionRightDisappearance {
    transition-duration: 0.5s;
    transition-delay: 0.3s;
    left: 150vw;
    visibility: hidden;
}


nav .directionRightAppearance span {
    animation-name: directionRightAppearanceArrow;
    animation-duration: 0.2s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
}

nav .directionRightAppearance:hover span{
    animation-fill-mode: none;
}

nav .directionRightAppearance:focus span{
    animation-fill-mode: none;
}

nav .directionRightAppearance span::before {
    animation-name: directionRightAppearanceArrowBefore;
    animation-duration: 0.2s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
}

nav .directionRightAppearance:hover span::before {
    animation-fill-mode: none;
}

nav .directionRightAppearance:focus span::before {
    animation-fill-mode: none;
}

nav .directionRightAppearance {
    animation-duration: 0.5s;
    animation-name: directionRightAppearance;
}

@keyframes directionRightAppearance{
    from{
        left: 150vw;
        visibility: hidden;
    }
    to{
        left: calc(95% - 40px);
        visibility: visible;
    }
}

@keyframes directionRightAppearanceArrow{
    from{
        rotate: 0deg;
        visibility: hidden;
    }
    to{
        rotate: 45deg;
        visibility: visible;
    }
}

@keyframes directionRightAppearanceArrowBefore{
    from{
        top: 0;
        left: 0;
        rotate: 180deg;
        visibility: hidden;
    }
    to{
        rotate: 90deg;
        left: 40px;
        top: 40px;
        visibility: visible;
    }
}






/***********************************************************************************************************************
left arrow
***********************************************************************************************************************/


nav .directionLeft::after{
    content: "";
    position: relative;
    color: var(--arrow-after-text-color);
    left: 100px;
    top: 83px;
    font-size: max(1.5vw, 20px);
    text-shadow: 0 0 1vw whitesmoke;
    opacity: 0;
    transition-duration: 0.5s;
}

nav .directionLeftContact::after{
    content: "Portfolio";
}

nav .directionLeftPortfolio::after{
    content: "Home";
}

nav .directionLeft:hover::after {
    left:  140px;
    opacity: 1;
}

nav .directionLeft:focus::after {
    left:  140px;
    opacity: 1;
}

nav .directionLeft{
    width: 200px;
    height: 200px;
    display: inline-block;
    position: fixed;
    top: calc(50vh - 100px);
    left: 0;
    z-index: 20;
}

nav .directionLeft span{
    width: 40%;
    background-color: var(--arrow-color);
    height: 3px;
    display: block;
    position: relative;
    top: 75px;
    left: 50px
}

nav .directionLeft span::before{
    content: "";
    width: 100%;
    background-color: var(--arrow-color);
    height: 3px;
    display: block;
    position: relative;
}


nav .directionLeft:hover span{
    rotate: -50deg;
}

nav .directionLeft:focus span{
    rotate: -50deg;
}

nav .directionLeft:hover span::before{
    rotate: -80deg;
    top: 38px;
    left: -46px;
}

nav .directionLeft:focus span::before{
    rotate: -80deg;
    top: 38px;
    left: -46px;
}

nav .directionLeftDisappearance span {
    rotate: 0deg;
    transition-duration: 0.2s;
}


nav .directionLeftDisappearance:hover span {
    rotate: 0deg;
    transition-duration: 0.2s;
}

nav .directionLeftDisappearance:focus span {
    rotate: 0deg;
    transition-duration: 0.2s;
}

nav .directionLeftDisappearance span::before {
    rotate: -180deg;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    visibility: hidden;
}

nav .directionLeftDisappearance:hover span::before {
    rotate: -180deg;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    visibility: hidden;
}

nav .directionLeftDisappearance:focus span::before {
    rotate: -180deg;
    transition-duration: 0.2s;
    top: 0;
    left: 0;
    visibility: hidden;
}

nav .directionLeftDisappearance {
    transition-duration: 0.5s;
    transition-delay: 0.3s;
    left: -50vw;
    opacity: 0;
    visibility: hidden;
}


nav .directionLeftAppearance span {
    animation-name: directionLeftAppearanceArrow;
    animation-duration: 0.2s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
}

nav .directionLeftAppearance:hover span{
    animation-fill-mode: none;
}

nav .directionLeftAppearance:focus span{
    animation-fill-mode: none;
}

nav .directionLeftAppearance span::before {
    animation-name: directionLeftAppearanceArrowBefore;
    animation-duration: 0.2s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
}

nav .directionLeftAppearance:hover span::before {
    animation-fill-mode: none;
}

nav .directionLeftAppearance:focus span::before {
    animation-fill-mode: none;
}

nav .directionLeftAppearance {
    animation-duration: 0.5s;
    animation-name: directionLeftAppearance;
}

@keyframes directionLeftAppearance{
    from{
        left: -50vw;
        visibility: hidden;
    }
    to{
        left: calc(5% + 40px);
        visibility: visible;
    }
}

@keyframes directionLeftAppearanceArrow{
    from{
        rotate: 0deg;
        visibility: hidden;
    }
    to{
        rotate: -45deg;
        visibility: visible;
    }
}

@keyframes directionLeftAppearanceArrowBefore{
    from{
        top: 0;
        left: 0;
        rotate: -180deg;
        visibility: hidden;
    }
    to{
        rotate: -90deg;
        left: -40px;
        top: 40px;
        visibility: visible;
    }
}





/****************************************************************************************************************
github and linkedin link in home page
*****************************************************************************************************************/



.github{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.github-hidden{
    visibility: hidden;
}

.linkedin{
    width: 30px;
    height: 30px;
    background-color: white;
    margin-right: 10px;
    border-radius: 5px;
}


@-moz-document url-prefix(){
    .linkedin{
        clip-path: rect(1px 1px 29px 29px );
        border-radius: 0;
    }
}

.linkedin-hidden{
    visibility: hidden;
}

.github-link{
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 0;
    height: 50px;
    background-color: var(--github);
    top: 40vh;
    transition-duration: 0.5s;
    left: 0;
}

.github-link:hover{
    width: 100px;
    transition-duration: 0.5s;
}

.github-link:focus{
    width: 100px;
    transition-duration: 0.5s;
}


.github-link-appearance{
    width: 50px;
    animation-name: github-link-appearance;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
}

@keyframes github-link-appearance{
    from{
        visibility: hidden;
        left: -50vw;
    }
    to{
        visibility: visible;
        left: 0;
    }
}

.github-link-appearance:hover{
    animation-fill-mode: none;
}

.github-link-appearance:focus{
    animation-fill-mode: none;
}

.github-link-hidden{
    transition-duration: 0.1s;
    width: 0;
    left: -1vw;
}


.linkedin-link{
    position: fixed;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 0;
    height: 50px;
    background-color: var(--linkedin);
    text-align: center;
    top: calc(40vh + 50px);
    transition-duration: 0.7s;
    
}

.linkedin-link:hover{
    width: 100px;
    transition-duration: 0.5s;
}

.linkedin-link:focus{
    width: 100px;
    transition-duration: 0.5s;
}


.linkedin-link-appearance{
    animation-name: linkedin-link-appearance;
    animation-fill-mode: forwards;
    animation-duration: 0.9s;
    width: 50px;
}

.linkedin-link-appearance:hover{
    animation-fill-mode: none;
}

.linkedin-link-appearance:focus{
    animation-fill-mode: none;
}

@keyframes linkedin-link-appearance{
    from{
        visibility: hidden;
        left: -50vw;
    }
    to{
        visibility: visible;
        left: 0;
    }
}


.linkedin-link-hidden{
    width: 0;
    transition-duration: 0.1s;
    transition-delay: 0.1s;
    left: -1vw;
}


/*****************************************************************************************************************
media screen
******************************************************************************************************************/

@media screen and (max-width: 1700px) {
    nav .directionRight::after{
        right: 20px;
    }
    
    nav .directionRight:hover::after {
        right:  50px;
        opacity: 1;
    }
    
    nav .directionRight:focus::after {
        right: 50px;
        opacity: 1;
    }
}

@media screen and (max-width: 1192px) {
    .project{
        max-width:none;
    }
    .project-container{
        grid-template-columns: auto;
        margin: 60px 290px 0;
        padding-bottom: 60px;
    }
}


@media screen and (max-width: 1004px) {
    nav .directionRight::after{
        visibility: hidden;
    }
    nav .directionLeft::after{
        visibility: hidden;
    }

    .project-container{
        grid-template-columns: auto;
        margin: 60px 190px 0;
    }
    
}

@media screen and (max-width: 992px) {
    

    .about h1{
        font-size: 4vw;
    }

    .about p {
        font-size: 2.5vw;
    }

    .language button:focus{
        border: none;
    }
    
    .language button:hover{
        font-size: 20px;
    }

    .form textarea{
        scrollbar-width: none;
    }
}



@media screen and (max-width: 768px) {
    .about{
        padding-top: 80px;
        min-height: auto;
    }

    .about h1{
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
    }

    .about p {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .project-container{
        margin: 60px 10px 0;
    }
    

    nav .directionRight{
        visibility: hidden;
    }
    nav .directionRightAppearance span {
        animation: none;
    }
    nav .directionRightAppearance span::before {
        animation: none;
    }
    nav .directionRightAppearance {
        animation: none;
    }

    nav .directionLeft{
        visibility: hidden;
    }
    nav .directionLeftAppearance span {
        animation: none;
    }
    nav .directionLeftAppearance span::before {
        animation: none;
    }
    nav .directionLeftAppearance {
        animation: none;
    }

    .github{
        margin: 0;
        width: 50px;
        height: 50px;
    }

    .github-link{
        width: 70px;
        height: 70px;
        justify-content: center;
        border-radius: 20px;
        position: relative;
        top: 0;
        overflow: hidden;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    

    .github-link::after{
        background-color: var(--github-button-hover);
        z-index: 10;
        position: absolute;
        content: "";
        width: 70px;
        height: 70px;
        transition-duration: 0.4s;
        transform: translate3d(-100%,0,0);
    }

    .github-link:hover{
        width: 70px;
    }

    .github-link:focus{
        width: 70px;
    }

    .github-link:hover::after{
        transform: translate3d(0,0,0);
    }
    
    .github-link:focus::after{
        transform: translate3d(0,0,0);
    }

    .github-link-appearance{
        animation-name: github-link-appearance;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }

    .github-link-appearance:hover{
        animation-fill-mode: forwards;
    }

    .github-link-appearance:focus{
        animation-fill-mode: forwards;
    }

    @keyframes github-link-appearance {
        from{
            top: 120vh;
        }
        to{
            top: 0;
        }
    }

    .github-link-hidden{
        animation-name: github-link-hidden;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;
    }

    @keyframes github-link-hidden {
        from{
            top: 0;
        }
        to{
            top: 120vh;
        }
    }

    .linkedin{
        margin: 0;
        width: 50px;
        height: 50px;
    }

    @-moz-document url-prefix(){
        .linkedin{
            clip-path: rect(1px 1px 49px 49px );
            border-radius: 0;
        }
    }

    .linkedin-link{
        width: 70px;
        height: 70px;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        top: 0;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .linkedin-link:hover{
        width: 70px;
    }

    .linkedin-link:focus{
        width: 70px;
    }


    .linkedin-link::after{
        background-color: var(--linkdedin-button-hover);
        z-index: 10;
        position: absolute;
        border-radius: 3px;
        content: "";
        width: 70px;
        height: 70px;
        transition-duration: 0.4s;
        transform: translate3d(-100%,0,0);
    }


    .linkedin-link:hover::after{
        transform: translate3d(0,0,0);
    }

    .linkedin-link:focus::after{
        transform: translate3d(0,0,0);
    }

    .linkedin-link-appearance{
        animation-name: linkedin-link-appearance;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }

    .linkedin-link-appearance:hover{
        animation-fill-mode: forwards;
    }

    @keyframes linkedin-link-appearance {
        from{
            top: 120vh;
        }
        to{
            top: 0;
        }
    }

    .linkedin-link-hidden{
        animation-name: github-link-hidden;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;
    }

    @keyframes linkedin-link-hidden {
        from{
            top: 0;
        }
        to{
            top: 120vh;
        }
    }

    .github-linkedin-button-container .github-button-text{
        visibility: hidden;
        width: 0;
    }

    .github-linkedin-button-container .linkedin-button-text{
        visibility: hidden;
        width: 0;
    }

    .github-linkedin-button-container .github-button{
        width: 70px;
        height: 70px;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .github-linkedin-button-container .github-button::after{
        width: 70px;
        height: 70px;
    }

    .github-linkedin-button-container .linkedin-button{
        width: 70px;
        height: 70px;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .github-linkedin-button-container .linkedin-button::after{
        width: 70px;
        height: 70px;
    }

    .github-button-img{
        margin: 10px;
        width: 50px;
        height: 50px;
    }

    .linkedin-button-img{
        margin: 10px;
        width: 50px;
        height: 50px;
        border-radius: 5px;
    }

    .github-linkedin-home-container{
        display: flex;
        justify-content: center;
    }

    .contact{
        width: auto;
    }
    .contact-container{
        display: block;
    }

    .contact h2, .contact h1 {
        padding-top: 80px;
        margin-left: 0;
        text-align: center;
    }

    .contact p{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;

    }

    .form{
        width: 100vw;
        align-items: center;
    }

    
    .form input{
        margin-left: 0;
        width: 80%;
    }

    .form textarea{
        width: 80%;
        margin-left: 0;
    }

    .github-linkedin-button-container{
        justify-content: center;
    }

    .portfolio{
        margin: 0;
        padding-top: 80px;
    }

}